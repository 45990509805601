import challengeSessionService from '@/services/challenge-session-service'

export default {
    state: {
        pendingChallengeSessions: [],
        expiredChallengeSessions: [],
        completedChallengeSessions: [],
        challengeSessionId: null,
        remainingChallengeSessions: 0,
        nextAvailableChallengeSessionDate: null,
        challengeId: null,
        joinerChallengerId: null
    },
    mutations: {
        setPendingChallengeSessions: (state, sessions) => {
            state.pendingChallengeSessions = sessions
        },
        setHistoryChallengeSessions: (state, sessions) => {
            state.completedChallengeSessions = sessions.filter(session => session.status === 'COMPLETED')
            state.expiredChallengeSessions = sessions.filter(session => session.status === 'EXPIRED')
        },
        setChallengeSessionId: (state, challengeSessionId) => {
            state.challengeSessionId = challengeSessionId
        },
        setRemainingChallengeSessions: (state, payload) => {
            state.remainingChallengeSessions = payload.remainingChallengeSessionsToPlay
            state.nextAvailableChallengeSessionDate = payload.nextAvailableChallengeSessionDate
        },
        setJoinerChallengerId: (state, data) => {
            state.joinerChallengerId = data
        },
        setChallengeId: (state, data) => {
            state.challengeId = data
        }
    },
    actions: {
        async fetchHistoryChallengeSessions({ commit }) {
            try {
                const response = await challengeSessionService.getHistoryChallengeSessions()
                commit('setHistoryChallengeSessions', response.data)
                return Promise.resolve()
            } catch (e) {
                commit('setHistoryChallengeSessions', [])
            }
        },
        async fetchPendingChallengeSessions({ commit }) {
            try {
                const response = await challengeSessionService.getPendingChallengeSessions()
                commit('setPendingChallengeSessions', response.data)
                return Promise.resolve()
            } catch (e) {
                commit('setPendingChallengeSessions', [])
            }
        },
        async fetchRemainingChallengeSessions({ commit }) {
            try {
                const response = await challengeSessionService.getRemainingChallengeSessions()
                commit('setRemainingChallengeSessions', response.data)
                return Promise.resolve()
            } catch (e) {
                commit('setRemainingChallengeSessions', 0)
            }
        },
        setCreateChallengeSessionData({ commit }, data) {
            commit('setJoinerChallengerId', data.joinerChallengerId)
            commit('setChallengeId', data.challengeId)
        },
        setChallengeSessionId({ commit }, payload) {
            commit('setChallengeSessionId', payload)
        },
        deleteChallengeSession({ commit, state }, payload) {
            const newPendingSessions = state.pendingChallengeSessions.filter(session => session.sessionId !== payload)
            commit('setPendingChallengeSessions', newPendingSessions)
        }
    }
}
