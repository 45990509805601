<template>
    <div
        class="progress-bar-container completed-missions"
    >
        <div
            class="progress-bar-label"
        >
            <span>
                {{ title }}
            </span>
            <span class="font-weight-bold">
                {{ localCurrent }}/{{ total }}
            </span>
        </div>
        <div class="progress-bar-background pb-2">
            <div
                class="progress-color"
                :style="{ width: progressBars(localCurrent, total) }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            currentValue: {
                type: Number,
                default: 0
            },
            total: {
                type: Number,
                default: 0
            }
        },
        computed: {
            localCurrent() {
                return this.currentValue > this.total ? this.total : this.currentValue
            }
        },
        methods: {
            progressBars(completedCount, totalCount) {
                return completedCount && totalCount ? `${(completedCount / totalCount) * 100}%` : '0%'
            }
        }
    }
</script>

<style scoped>
.progress-bar-container {
    width: 100%;
    padding: 1rem 0;
}

.progress-bar-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
    text-align: left;
    font-weight: 400;
    color: var(--sidebar-text);
}

.progress-bar-background {
    height: 20px;
    background-color: var(--sidebar-btn-text-color);
    border-radius: 20px;
    padding: 5px;
}

.progress-color {
    background-color: var(--sidebar-btn-bg-color);
    height: 10px;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}
</style>
