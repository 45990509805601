<template>
    <b-modal
        id="first-coupon-modal"
        ref="first-coupon-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>

            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <img :src="require('@/assets/images/modal-icons/first-coupon-icon.png')" alt="coupon icon">
                </b-col>
            </b-row>
        </div>

        <div class="footer-container">
            <div>
                <div class="body-head">
                    {{ $t('firstCouponModal.congratulations') }}
                </div>
                <div class="body-text">
                    {{ $t('firstCouponModal.successMessage') }}
                </div>

                <div
                    class="go-to-rewards"
                    @click="goToRewards"
                >
                    {{ $t('firstCouponModal.goToRewards') }}
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'

    export default {
        created() {
            emitter.on('openFirstCouponModal', () => {
                this.$bvModal.show('first-coupon-modal')
            })
        },
        methods: {
            hideModal() {
                this.$refs['first-coupon-modal'].hide()
            },
            hideTaskModal() {
                emitter.emit('closeTaskEndModal')
            },
            goToRewards() {
                this.$router.push({ name: 'rewards' })
                this.hideModal()
                this.hideTaskModal()
            }
        }
    }
</script>
<style>
#first-coupon-modal.modal {
    backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background:
        url("https://s3.eu-west-1.amazonaws.com/dev-games.mpassltd.com/img/badges/f416664a-8afc-48fc-9ef9-8973ebe88805-first-coupon-header.png"),
        linear-gradient(to right, #432bb3 0%, #b666d0 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 6rem 0 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 200px;
    top: 100%;
    transform: translateY(-50%);
}

.avatar-container img {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.body-head {
    font-weight: 700;
    font-size: 1.25rem;
    color: #452cb4;
    text-align: center;
    margin: 0 auto;
}

.body-text {
    font-weight: 400;
    font-size: 1rem;
    color: #452cb4;
    text-align: center;
    margin: 0 auto;
    padding: 0.5rem 1rem;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.go-to-rewards {
    margin: 2rem auto;
    width: 100%;
    max-width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b365d0;
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
    color: var(--white);
    border-radius: 6px;
    text-decoration: none;
    cursor: pointer;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
