export default {
    initWidget() {
        console.log('initWidget')
        function loadCss(url) {
            const head = document.getElementsByTagName('HEAD')[0]
            const link = document.createElement('link')
            link.rel = 'stylesheet'
            link.type = 'text/css'
            link.href = url
            head.appendChild(link)
        }

        function loadJS(url) {
            const head = document.getElementsByTagName('HEAD')[0]
            const script = document.createElement('script')
            script.setAttribute('src', url)
            script.setAttribute('type', 'text/javascript')
            script.setAttribute('async', true)
            head.appendChild(script)
        }

        function appendElement() {
            const widget = document.createElement('mysocialpass-widget')
            document.body.appendChild(widget)
        }

        appendElement()
        loadCss('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css')
        loadJS('https://chat.mysocialpass.com/widget/widgetLib.js')
    }
}
