import playerService from '@/services/player-service'
import rankService from '@/services/rank-service'
import router from '@/router'
import missionService from '@/services/mission-service'
import dateUtils from '@/utils/date-conversion'
export default {
    state: {
        isLoggedIn: localStorage.getItem(`${process.env.VUE_APP_API_HOST_DOMAIN}-web`),
        player: {},
        couponModalshown: sessionStorage.getItem('couponModalshown') === 'true' || false,
        ranks: [],
        totalPlayers: 0
    },
    mutations: {
        login(state, token) {
            localStorage.setItem(`${process.env.VUE_APP_API_HOST_DOMAIN}-web`, token)
            state.isLoggedIn = true
        },
        logout(state) {
            state.player = {}
            state.isLoggedIn = null
            localStorage.removeItem(`${process.env.VUE_APP_API_HOST_DOMAIN}-web`)
        },
        setPlayer(state, player) {
            state.player = player
        },
        setRanks(state, ranks) {
            state.ranks = ranks
        },
        setCouponModalshown(state, couponModalshown) {
            state.couponModalshown = couponModalshown
            sessionStorage.setItem('couponModalshown', couponModalshown)
        },
        setTotalPlayers(state, totalPlayers) {
            state.totalPlayers = totalPlayers
        },
        setChallengerId(state, challengerId) {
            state.player.challengerId = challengerId
        }
    },
    actions: {
        loginWithCredentials({ commit, dispatch }, credentials) {
            return new Promise((resolve, reject) => {
                playerService.loginWithCredentials({
                    username: credentials.username,
                    password: credentials.password
                }).then(response => {
                    commit('login', response.data.token)
                    resolve(response)
                    router.push({ name: 'home' })
                }).catch(error => {
                    if (error.response && [403, 401].includes(error.response.status)) {
                        dispatch('logout')
                        router.push({ path: `/login` })
                    }
                    reject(error)
                })
            })
        },
        loginWithAccessCode({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                playerService.loginWithAccessCode({
                    accessCode: payload.accessCode,
                    reCaptchaResponse: payload.reCaptchaResponse
                }).then(response => {
                    commit('login', response.data.token)
                    resolve(response)

                    router.push({ name: 'home' })
                }).then(() => {
                    if (payload.freeTaskInfo) {
                        missionService.updateFreeTask(payload.freeTaskInfo)
                            .finally(() => {
                                commit('clearFreeTaskInfo')
                            })
                    }
                }).catch(error => {
                    if (error.response && [403, 401].includes(error.response.status)) {
                        dispatch('logout')
                        router.push({ path: `/login` })
                    }
                    reject(error)
                })
            })
        },
        logout({ commit }) {
            commit('logout')
        },
        refreshCurrentUser({ commit, dispatch }) {
            playerService.getCurrentPlayer().then((res) => {
                let player = res.data
                commit('setPlayer', player)
                commit('setUpcomingFlights', player.boardingPasses.filter(flight => !flight.flightStatus))
                commit('setTodayFlights', player.boardingPasses.filter(flight => flight.flightStatus))
            }).catch((error) => {
                if (error.response && [403, 401].includes(error.response.status)) {
                    dispatch('logout')
                    router.push({ path: `/welcome` })
                }
            })
        },
        fetchRanks({ commit }) {
            rankService.getRanks().then((res) => {
                let ranks = res.data.ranks
                commit('setTotalPlayers', res.data.totalPlayers)

                for (let rank of ranks) {
                    rank.digitString = dateUtils.getPaddedDigitsString(rank.totalPlayDurationMs)
                }
                commit('setRanks', ranks)
            })
        },
        setChallengerId({ commit }, challengerId) {
            commit('setChallengerId', challengerId)
        }
    }
}
