import moment from 'moment'

function prepareRemainingTimeString({
    includeDays,
    includeSeconds,
    days,
    hours,
    minutes,
    seconds,
    suffices,
    delimiter
}) {
    let result = ''

    if (includeDays && days > 0) {
        result += `${days.toFixed(0).padStart(2, '0')}${suffices.days}${delimiter}`
    }
    result += `${hours.toFixed(0).padStart(2, '0')}${suffices.hours}${delimiter}${minutes.toFixed(0).padStart(2, '0')}${suffices.minutes}`
    if (includeSeconds) {
        result += `${delimiter}${seconds.toFixed(0).padStart(2, '0')}${suffices.seconds}`
    }

    return result
}

export default {
    utcToLocalTime(time, format) {
        return moment(time).tz('Europe/Athens').format(format)
    },
    toIsoString(dateTime) {
        return moment(dateTime).toISOString()
    },
    utcToDate(dateTime) {
        return moment(dateTime).utc().format('YYYY-MM-DD')
    },
    getPaddedDigitsString(timeSpent, hours = true, minutes = true, seconds = true, milliseconds = true) {
        return moment.utc(timeSpent).format(`${hours ? 'HH:' : ''}${minutes ? 'mm:' : ''}${seconds ? 'ss' : ''}${milliseconds ? '.SS' : ''}`)
    },
    getRemainingTime(fromTime, toTime, includeDays, includeSeconds, useCapital, delimiter) {
        const suffices = useCapital ? {
            days: 'D',
            hours: 'H',
            minutes: 'M',
            seconds: 'S'
        } : {
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's'
        }

        const duration = moment.duration(moment(fromTime).diff(moment(toTime)))

        if (duration.asMilliseconds() < 0) {
            return prepareRemainingTimeString({
                includeDays,
                includeSeconds,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                suffices,
                delimiter
            })
        }

        const days = Math.floor(duration.asDays())
        duration.subtract(moment.duration(days, 'days'))

        const hours = Math.floor(duration.hours())
        duration.subtract(moment.duration(hours, 'hours'))

        const minutes = Math.floor(duration.minutes())
        duration.subtract(moment.duration(minutes, 'minutes'))

        const seconds = Math.floor(duration.seconds())

        return prepareRemainingTimeString({
            includeDays,
            includeSeconds,
            days,
            hours,
            minutes,
            seconds,
            suffices,
            delimiter
        })
    }
}
