<template>
    <div
        class="player-badges"
    >
        <div class="badges-header">
            <span
                class="badges-tooltip"
                @click="toggleTooltip"
            >
                <font-awesome-icon
                    class="tooltip-icon"
                    :icon="['fas', 'info-circle']"
                />
                <div
                    v-if="isTooltipVisible"
                    ref="tooltipBody"
                    class="tooltip-text"
                >
                    <span class="tool-tip-body">
                        {{ $t('sidebar.badgesTooltip') }}
                    </span>
                </div>
            </span>
            <div class="badges-text">
                <span class="total-badges">{{ $t('sidebar.totalBadges') }}:</span> {{ currentTotalBadges }} / {{ totalMissionCount }}
            </div>
        </div>
        <div class="badges-container">
            <badge
                v-for="playerBadge in playerBadges"
                :key="playerBadge.badge.id"
                :alias="playerBadge.badge.alias"
                :badge-url="playerBadge.badge.imageUrl"
                :player-badges="playerBadge.count"
            />
        </div>
    </div>
</template>

<script>
    import Badge from '@/components/Badge'
    export default {
        components: {
            Badge
        },
        data() {
            return {
                isTooltipVisible: false
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            playerBadges() {
                return this.player?.playerBadges
            },
            currentTotalBadges() {
                let totalBadges = 0
                if (this.playerBadges?.length > 0) {
                    this.playerBadges.forEach(badge => {
                        totalBadges += badge.count
                    })
                }
                return totalBadges
            },
            totalMissionCount() {
                return this.$store.getters.getTotalMissionCount
            }
        },
        methods: {
            toggleTooltip() {
                this.isTooltipVisible = !this.isTooltipVisible
            }
        }
    }
</script>

<style scoped>
.player-badges {
    width: 95%;
    color: var(--sidebar-text);
}

.badges-header {
    margin: 0.5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.badges-text {
    text-align: center;
}

.total-badges {
    font-weight: 400;
}

.badges-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 1rem;
    row-gap: 0.25rem;
    padding: 1rem 0 0.5rem;
}

.badges-tooltip {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    width: 200px;
    background-color: var(--sidebar-text);
    color: var(--sidebar-background);
    border-radius: 6px;
    padding: 0.5rem;
    line-height: 1.25;
    text-align: left;
    position: absolute;
    top: 120%;
    left: -100%;
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 20%));
    z-index: 2;
}

.tooltip-text::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 11.5%;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #fff;
}

.tool-tip-body {
    font-size: 0.8rem;
}

.tooltip-icon {
    color: var(--sidebar-text);
    cursor: pointer;
    font-size: 1.25rem;
}

</style>
