import emitter from '@/utils/emitter'
export default {
    computed: {
        couponNeededPoints() {
            return this.$store.getters.getCouponNeededPoints
        },
        couponModalshown() {
            return this.$store.getters.getCouponModalShown
        }
    },
    methods: {
        checkIfWonCoupon(player) {
            const totalAvailablePoints = player?.totalAvailablePoints

            const showFirstCouponModal = this.isRewardsEnabled && totalAvailablePoints >= this.couponNeededPoints
            if (showFirstCouponModal && !this.couponModalshown) {
                this.openFirstCouponModal()
            }
        },
        openFirstCouponModal() {
            emitter.emit('openFirstCouponModal')
            this.$store.commit('setCouponModalshown', true)
        }
    }
}
