import configService from '@/services/config-service'
const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE || 'EN'

export default {
    state: {
        language: localStorage.getItem('language') || defaultLanguage,
        availableLanguages: [],
        previewMode: localStorage.getItem('preview-mode') === 'true' || false,
        cheatMode: localStorage.getItem('cheat-mode') === 'true' || false,
        couponNeededPoints: 0,
        config: {
            features: {},
            inCodeLabels: {},
            playerProfileFields: {},
            playerProfileDropdownFields: {},
            theming: {},
            branding: {},
            leaderboards: {}
        },
        timers: {},
        commercialBanners: [],
        deviceType: null,
        sc: localStorage.getItem('scan-code') || null
    },
    mutations: {
        setLanguage: (state, language) => {
            state.language = language
            localStorage.setItem('language', language)
        },
        setAvailableLanguages: (state, languages) => {
            state.availableLanguages = languages
        },
        setConfig(state, config) {
            state.config = config
        },
        setPreviewMode(state, previewMode) {
            state.previewMode = previewMode
            localStorage.setItem('preview-mode', previewMode)
        },
        setCheatMode(state, cheatMode) {
            state.cheatMode = cheatMode
            localStorage.setItem('cheat-mode', cheatMode)
        },
        setCommercialBanners: (state, commercialBanners) => {
            state.commercialBanners = commercialBanners
        },
        setScanCode(state, sc) {
            state.sc = sc
            localStorage.setItem('scan-code', sc)
        },
        setDeviceType(state, deviceType) {
            state.deviceType = deviceType
        },
        setCouponNeededPoints(state, couponNeededPoints) {
            state.couponNeededPoints = couponNeededPoints
        }
    },
    actions: {
        async initConfig({ commit }) {
            const config = await configService.getConfig()
            commit('setConfig', config.data)
        }
    }
}
