<template>
    <b-navbar class="navigation p-0" fixed toggleable="lg">
        <b-navbar-toggle id="navbarToggler" class="toggler" target="nav-collapse">
            <template #default>
                <burger-svg />
            </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mr-auto main-nav">
                <b-nav-item :to="'/home'">
                    <img v-if="theming.navigationHomeIcon" :src="theming.navigationHomeIcon" alt="" class="nav-icon">
                    <home-svg v-else class="nav-icon" />
                    <span
                        class="nav-item-text ml-1 mr-1"
                    >
                        {{ $t('navigation.home') }}
                    </span>
                </b-nav-item>
                <b-nav-item-dropdown
                    class="mission-dropdown"
                >
                    <template slot="button-content">
                        <img v-if="theming.navigationTaskIcon" :src="theming.navigationTaskIcon" alt="" class="nav-icon">
                        <task-svg v-else class="nav-icon" />
                        <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.taskSummary') }}</span>
                    </template>
                    <b-dropdown-item
                        v-for="mission in missions"
                        :key="mission.id"
                        :disabled="mission.locked"
                        :to="`/missions/${mission.id}/tasks/${mission.tasks[0].id}`"
                    >
                        <div class="nav-item-text">
                            {{ mission.alias }}
                        </div>
                        <span v-if="mission.locked" class="badge badge-danger">{{ $t('navigation.locked') }}</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item
                    v-if="featureConfig && featureConfig.leaderboardEnabled"
                    :to="'/leaderboard'"
                >
                    <img
                        v-if="theming.navigationLeaderboardIcon"
                        :src="theming.navigationLeaderboardIcon"
                        alt=""
                        class="nav-icon"
                    >
                    <leader-board-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.leaderBoard') }}</span>
                </b-nav-item>
                <b-nav-item
                    v-if="featureConfig && featureConfig.libraryEnabled"
                    :to="'/library'"
                    data-testid="menu-library-section"
                >
                    <img v-if="theming.navigationLibraryIcon" :src="theming.navigationLibraryIcon" alt="" class="nav-icon">
                    <library-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.library') }}</span>
                </b-nav-item>
                <b-nav-item
                    v-if="featureConfig && featureConfig.rewardsEnabled"
                    :to="'/rewards'"
                    data-testid="menu-rewards-section"
                >
                    <img v-if="theming.navigationRewardsIcon" :src="theming.navigationRewardsIcon" alt="" class="nav-icon">
                    <rewards-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.rewards') }}</span>
                </b-nav-item>
                <b-nav-item-dropdown
                    v-if="bonusActivitiesEnabled"
                    class="bonus-dropdown"
                >
                    <template slot="button-content">
                        <img v-if="theming.navigationBonusIcon" :src="theming.navigationBonusIcon" alt="" class="nav-icon">
                        <bonus-svg v-else class="nav-icon" />
                        <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.bonus') }}</span>
                    </template>
                    <b-dropdown-item
                        v-if="(featureConfig && featureConfig.inCodeEnabled) && inCodeLabels"
                        :to="`/submit-code`"
                        class="bonus-dropdown-item"
                        data-testid="menu-incodes-section"
                    >
                        <div :class="{'no-bg' : theming.navigationPnrIcon}" class="icon-container star">
                            <img v-if="theming.navigationPnrIcon" :src="theming.navigationPnrIcon" alt="" class="nav-dropdown-icon">
                            <pnr-svg v-else class="nav-dropdown-icon" />
                        </div>
                        <span>
                            {{ labelText('mainTitle') }}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="featureConfig && featureConfig.surveysEnabled"
                        :to="`/surveys`"
                        class="bonus-dropdown-item"
                        data-testid="menu-surveys-section"
                    >
                        <div :class="{'no-bg' : theming.navigationSurveyIcon}" class="icon-container smile">
                            <img
                                v-if="theming.navigationSurveyIcon"
                                :src="theming.navigationSurveyIcon"
                                alt=""
                                class="nav-dropdown-icon"
                            >
                            <smile-svg v-else class="nav-dropdown-icon" />
                        </div>
                        <span>
                            {{ $t('navigation.survey') }}
                        </span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item
                    v-if="areChallengesEnabled"
                    :class="{'new': hasPendingChallenges}"
                    :to="'/challenges'"
                    class="arena-icon"
                    data-testid="challenge-section"
                >
                    <arena-svg class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.arena') }}</span>
                </b-nav-item>
                <b-nav-item
                    v-if="isBoardingScanEnabled"
                    :class="{'new': todayFlights}"
                    :to="'/my-flights'"
                    class="boarding-pass-icon"
                >

                    <img
                        v-if="theming.navigationBoardingIcon"
                        :src="theming.navigationBoardingIcon"
                        alt=""
                        class="nav-icon"
                    >
                    <my-flights-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">   {{ $t('navigation.myFlights') }}</span>

                </b-nav-item>
                <b-nav-item :to="`/settings`" class="mobile-only">
                    <img v-if="theming.navigationSettingsIcon" :src="theming.navigationSettingsIcon" alt="" class="nav-icon">
                    <settings-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.settings') }}</span>
                </b-nav-item>
                <b-nav-item
                    class="mobile-logout"
                    @click="logout"
                >
                    <img v-if="theming.navigationLogoutIcon" :src="theming.navigationLogoutIcon" alt="" class="nav-icon">
                    <logout-svg v-else class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.exit') }}</span>
                </b-nav-item>
                <div
                    v-if="areAvailableLanguagesMoreThanOne"
                    class="lang-choices mobile"
                >
                    <img v-if="theming.navigationLanguageIcon" :src="theming.navigationLanguageIcon" alt="" class="nav-icon">
                    <language-svg v-else class="nav-icon" />
                    <language-select-mobile />
                </div>
                <b-nav-item
                    v-if="branding.termsPdfUrl"
                    :href="branding.termsPdfUrl"
                    class="mobile-only"
                    target="_blank"
                >
                    <span class="nav-item-text ml-1 mr-1">{{ $t('sidebar.terms') }}
                    </span>
                </b-nav-item>

            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="avatar-menu">
                <b-nav-item-dropdown
                    class="avatar-link"
                    right
                >
                    <template slot="button-content">
                        <avatar
                            :avatar="player.avatar || null"
                            avatar-width="45px"
                        />
                    </template>
                    <b-dropdown-item
                        :to="`/edit-profile`"
                    >
                        <img
                            v-if="theming.navigationEditProfileIcon"
                            :src="theming.navigationEditProfileIcon"
                            alt=""
                            class="profile-dropdown-icon"
                        >
                        <edit-profile-svg v-else class="profile-dropdown-icon" />
                        <span class="nav-item-text ml-1">{{ $t('navigation.editProfile') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="`/settings`"
                        class="desktop-only"
                    >
                        <img
                            v-if="theming.navigationSettingsIcon"
                            :src="theming.navigationSettingsIcon"
                            alt=""
                            class="profile-dropdown-icon"
                        >
                        <settings-svg v-else class="profile-dropdown-icon" />
                        <span class="nav-item-text ml-1">{{ $t('navigation.settings') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="logout"
                        @click="logout"
                    >
                        <img
                            v-if="theming.navigationLogoutIcon"
                            :src="theming.navigationLogoutIcon"
                            alt=""
                            class="profile-dropdown-icon"
                        >
                        <logout-svg v-else class="profile-dropdown-icon" />
                        <span class="nav-item-text ml-1">
                            {{ $t('navigation.exit') }}
                        </span>
                    </b-dropdown-item>
                    <div
                        v-if="areAvailableLanguagesMoreThanOne"
                        class="lang-choices desktop"
                    >
                        <img
                            v-if="theming.navigationLanguageIcon"
                            :src="theming.navigationLanguageIcon"
                            alt=""
                            class="profile-dropdown-icon"
                        >
                        <language-svg v-else class="profile-dropdown-icon" />
                        <language-select-mobile class="ml-1" />
                    </div>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import HomeSvg from '@/components/svg/HomeSVG'
    import TaskSvg from '@/components/svg/TaskSVG'
    import LeaderBoardSvg from '@/components/svg/LeaderBoardSVG'
    import LibrarySvg from '@/components/svg/LibrarySVG'
    import RewardsSvg from '@/components/svg/RewardsSVG'
    import BonusSvg from '@/components/svg/BonusSVG'
    import ArenaSvg from '@/components/svg/ArenaSVG'
    import SmileSvg from '@/components/svg/SmileSVG'
    import BurgerSvg from '@/components/svg/BurgerSVG'
    import LogoutSvg from '@/components/svg/LogoutSVG'
    import MyFlightsSvg from '@/components/svg/MyFlightsSVG'
    import EditProfileSvg from '@/components/svg/EditProfileSVG'
    import Avatar from '@/components/Avatar'
    import PnrSvg from '@/components/svg/PnrSVG'
    import SettingsSvg from '@/components/svg/SettingsSVG'
    import LanguageSvg from '@/components/svg/LanguageSVG'
    import LanguageSelectMobile from '@/components/LanguageSelectMobile'
    import emitter from '@/utils/emitter'

    export default {
        name: 'Navigation',
        components: {
            HomeSvg,
            TaskSvg,
            LeaderBoardSvg,
            MyFlightsSvg,
            LibrarySvg,
            RewardsSvg,
            BonusSvg,
            SmileSvg,
            BurgerSvg,
            LogoutSvg,
            EditProfileSvg,
            Avatar,
            PnrSvg,
            SettingsSvg,
            ArenaSvg,
            LanguageSvg,
            LanguageSelectMobile
        },
        props: {
            player: {
                type: Object,
                default: () => {
                }
            },
            missions: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            inCodeLabels() {
                return this.$store.getters.getInCodeLabels
            },
            featureConfig() {
                return this.$store.getters.getConfigFeatures
            },
            branding() {
                return this.$store.getters.getBranding
            },
            bonusActivitiesEnabled() {
                return (this.featureConfig && this.featureConfig.inCodeEnabled && this.inCodeLabels) || (this.featureConfig && this.featureConfig.surveysEnabled)
            },
            areAvailableLanguagesMoreThanOne() {
                return this.$store.getters.getAvailableLanguages && this.$store.getters.getAvailableLanguages.length > 1
            },
            theming() {
                return this.$store.getters.getTheming
            },
            currentPlayer() {
                return this.$store.getters.getCurrentUser
            },

            hasPendingChallenges() {
                return this.$store.getters.getPendingChallengeSessions?.filter(session => !session.creator)?.length > 0
            },
            areChallengesEnabled() {
                return this.$store.getters.areChallengesEnabled
            },
            isBoardingScanEnabled() {
                return this.$store.getters.isBoardingScanEnabled
            },
            todayFlights() {
                return this.currentPlayer?.boardingPasses?.filter(flight => flight.flightStatus).length > 0
            }
        },
        methods: {
            labelText(fieldName) {
                return this.inCodeLabels ? this.inCodeLabels[fieldName] : ''
            },
            logout() {
                emitter.emit('openLogoutModal')
            }
        }
    }
</script>

<style scoped>
.toggler {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
}

.navbar-collapse {
    position: absolute;
    top: 0;
    right: 0.5rem;
    z-index: 10;
    transform: translateY(5%);
    min-width: 220px;
}

.navigation {
    font-size: 14px;
    font-weight: 700;
    background-color: var(--navigation-background);
    position: relative;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--navigation-text) !important;
}

.navbar-nav .main-nav {
    overflow: hidden;
}

.nav-item-text {
    color: var(--navigation-text) !important;
    font-weight: 700;
    border: none;
}

.lang-choices {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    background-color: var(--navigation-background);
    gap: 0.5rem;
}

.lang-choices.desktop {
    display: none;
}

.lang-choices.mobile {
    border-bottom: 1px solid var(--light-secondary-color);
}

.nav-item.mobile-logout {
    display: flex !important;
    margin: 0 auto;
}

.icon-container {
    background: var(--primary-color);
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 0.25rem;
}

.no-bg {
    background: none;
}

.nav-icon {
    width: 35px;
    height: 35px;
    margin-right: 0.25rem;
}

.arena-icon,
.boarding-pass-icon {
    position: relative;
}

.arena-icon.new::before,
.boarding-pass-icon.new::before {
    content: "";
    position: absolute;
    top: 20%;
    right: 10%;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
}

.arena-icon.new::after,
.boarding-pass-icon.new::after {
    content: "";
    position: absolute;
    top: 21%;
    right: 10.5%;
    width: 4px;
    height: 4px;
    background: rgba(248 140 140 / 50%);
    border-radius: 50%;
}

.nav-dropdown-icon {
    width: 45px;
    height: auto;
}

.profile-dropdown-icon {
    width: 26px;
    height: auto;
}

.avatar-menu {
    display: none;
}

@media screen and (min-width: 992px) {
    .nav-item.mobile-logout {
        display: none !important;
    }

    .navbar-collapse {
        position: relative;
        right: unset;
        left: 0;
        transform: translateY(0);
        width: 100%;
    }

    .avatar-menu {
        display: flex;
        margin-left: auto;
    }

    .lang-choices {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        background-color: var(--navigation-background);
        gap: 0.5rem;
    }

    .lang-choices.desktop {
        display: flex;
    }

    .lang-choices.mobile {
        display: none;
    }
}

@media screen and (min-width: 1199px) {
    .navbar-collapse {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        transform: translateY(0);
    }
}

</style>
<style lang="scss">
.navbar-light .navbar-toggler {
    border-color: transparent !important;
    padding: 0;
}

.dropdown-item {
    color: var(--navigation-text) !important;
    padding: 0.5rem 1rem !important;
    display: flex !important;
    gap: 0.5rem;
    align-items: center;
}

.bonus-dropdown-item {
    display: flex !important;
    gap: 0.5rem;
}

.dropdown-item span {
    white-space: normal;
    text-align: left;
    display: block;
}

.dropdown-item.disabled {
    color: var(--disabled-color) !important;
}

.nav-link {
    padding: 1rem 0.75rem !important;
    transition: all 0.15s ease-in;
    width: 100%;
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    height: 70px;
    border-bottom: 1px solid var(--light-secondary-color);
}

.nav-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    height: 70px;
}

.mission-dropdown .dropdown-menu {
    width: 130%;
    position: absolute !important;
    top: 100%;
    right: 10%;
    left: unset;
    background-color: var(--navigation-background) !important;
}

.bonus-dropdown .dropdown-menu {
    width: 130%;
    position: absolute !important;
    top: 100%;
    right: 20%;
    left: unset;
    background-color: var(--navigation-background) !important;
}

.mission-dropdown .dropdown-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.mobile-logout {
    text-transform: uppercase;
    color: var(--white);
    width: 100%;
}

@media screen and (min-width: 768px) {
    .nav-link {
        padding: 1rem 0.75rem !important;
        transition: all 0.15s ease-in;
        width: 100%;
    }

    .nav-item,
    .nav-link {
        display: flex !important;
        align-items: center;
        flex-wrap: nowrap !important;
        height: 70px;
    }

    .avatar-link:hover {
        background-color: none !important;
    }

    .mission-dropdown .dropdown-menu {
        width: 250%;
        right: 20%;
    }

    .bonus-dropdown .dropdown-menu {
        width: 130%;
    }
}

@media screen and (min-width: 992px) {
    .nav-link {
        border-bottom: unset;
    }

    .nav-link:hover,
    .dropdown-item:hover {
        background-color: var(--navigation-hover-color) !important;
    }

    .mission-dropdown .dropdown-menu {
        width: 300%;
        right: unset;
        left: 0;
    }

    .bonus-dropdown .dropdown-menu {
        width: 200%;
        right: 0;
        top: 100%;
    }

    .bonus-dropdown .dropdown-item:active {
        background: var(--navigation-background) !important;
        color: var(--navigation-text) !important;
    }

    .avatar-menu .dropdown-menu {
        background-color: var(--navigation-background) !important;
    }
}

.dropdown-toggle {
    white-space: normal !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-toggle span::after {
    display: inline-block;
    margin-left: 0.5rem;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    color: var(--navigation-text);
}

.dropdown-item:active {
    background: var(--navigation-background) !important;
    color: var(--navigation-text) !important;
}

.navigation .dropdown-menu {
    outline: none;
}

.navigation .dropdown-item:active {
    background: var(--navigation-background) !important;
    color: var(--navigation-text) !important;
}

</style>
