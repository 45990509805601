import restApi from '@/utils/rest-api'

export default {
    getRanks: (size) => {
        if (size === undefined) {
            size = 10
        }
        return restApi.get(`ranks?noOfRanks=${size}`)
    }
}
