<template>
    <div class="lang-preference">
        <label
            v-for="(availableLanguage, index) in availableLanguages"
            :key="index"
            class="radio-btn"
        >
            <span
                class="lang"
                :class="{'active': selectedLanguage === availableLanguage}"
            >{{ availableLanguage }}</span>
            <input
                v-model="selectedLanguage"
                type="radio"
                :value="availableLanguage"
                name="radio"
            >
        </label>
    </div>
</template>

<script>
    export default {
        computed: {
            selectedLanguage: {
                get() {
                    return this.$store.getters.getLanguage
                },
                set(newValue) {
                    this.$store.commit('setLanguage', newValue)
                    location.reload()
                }
            },
            availableLanguages() {
                return this.$store.getters.getAvailableLanguages
            }
        }
    }
</script>

<style scoped>
.lang-preference {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--navigation-text);
    gap: 1rem;
}

.radio-btn {
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 0;
}

.radio-btn input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.lang.active {
    font-weight: bold;
}

.radio-btn:not(:first-child) {
    border-left: 2px solid var(--navigation-icon-color);
    padding-left: 1rem;
}
</style>
