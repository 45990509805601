<template>
    <b-modal
        id="boarding-pass-consent-modal"
        ref="boarding-pass-consent-modal"
        :hide-footer="true"
        :hide-header="true"
        centered
        class="modal-content"
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal">
                <font-awesome-icon :icon="[ 'far', 'times-circle' ]" />
            </b-button>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <img :src="theming.boardingPassImageUrl" alt="ticketImage">
                </b-col>
            </b-row>
        </div>

        <div class="footer-container">

            <div class="body-head">
                {{ $t('myFlights.consentInstructions') }}
            </div>

            <label
                :style="{
                    '--checkmark-color': theming.boardingButtonColor
                }"
                class="custom-checkbox"
            >
                <div class="checkbox-label">
                    {{ $t('myFlights.consentCheckText') }}
                </div>
                <input
                    v-model="consent"
                    type="checkbox"
                >
                <span class="checkmark" />
            </label>

            <div v-if="errorMessageToShow" class="error-container">
                <span class="error-message">{{ errorMessageToShow }}</span>
            </div>

            <button
                :class="{ 'disabled': !consent }"
                :style="{
                    backgroundColor: theming.boardingButtonColor,
                    color: theming.boardingButtonTextColor
                }"
                class="custom-btn"
                @click="submitConsent"
            >
                {{ $t('myFlights.modalBtnLabel') }}

            </button>

        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'
    import boardingPassService from '@/services/boarding-pass-service'

    export default {
        data() {
            return {
                consent: false,
                errorMessageToShow: null
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            },
            taskId() {
                return parseInt(this.$route.params.taskId)
            },
            missionId() {
                return parseInt(this.$route.params.missionId)
            },
            task() {
                return this.$store.getters.getTask(this.missionId, this.taskId)
            },
            mission() {
                return this.$store.getters.getMission(this.missionId)
            },
            player: {
                get() {
                    return this.$store.getters.getCurrentUser
                },
                set(value) {
                    this.$store.commit('setPlayer', value)
                }
            }
        },
        created() {
            emitter.on('openBoardingPassConsentModal', () => {
                this.$bvModal.show('boarding-pass-consent-modal')
            })
        },
        mounted() {
            this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
                if (modalId === 'boarding-pass-consent-modal') {
                    this.errorMessageToShow = ''
                }
            })
        },
        methods: {
            hideModal() {
                this.$refs['boarding-pass-consent-modal'].hide()
                this.errorMessageToShow = ''
            },
            async submitConsent() {
                try {
                    this.player.boardingPassDataConsentGiven = true
                    await boardingPassService.updateUserDataConsent()
                    this.goToScanRoute()
                } catch (error) {
                    this.handleError(error)
                }
            },
            goToScanRoute() {
                this.hideModal()
                this.$router.push('/boarding-pass')
            },
            handleError(error) {
                if (error.response?.data?.code === 'LAST_REQUESTED_OTP_STILL_ACTIVE') {
                    this.errorMessageToShow = 'The last requested code is still active. You can find it in your preferred communications channel inbox.'
                }
            }
        }
    }
</script>
<style>
#boarding-pass-consent-modal .modal {
    backdrop-filter: blur(2px);
}

#boarding-pass-consent-modal .modal-body {
    min-height: 400px !important;
    max-width: 600px !important;
}

#boarding-pass-consent-modal .modal-dialog {
    max-width: 600px !important;
}
</style>
<style lang="scss" scoped>
.header-container {
    background: linear-gradient(90deg, #091e40 0%, #2a7e8e 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 4rem 2rem 1rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 200px;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-container img {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.body-head,
.consent-checkbox {
    font-size: 1rem;
    color: #071731;
    text-align: center;
    margin: 0 auto;
}

.custom-checkbox {
    margin: 2rem auto 1.5rem;
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    padding-left: 1.75rem;
    width: 90%;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 9%;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: 2px solid var(--checkmark-color);
    transition: all 0.15s ease-out;
    border-radius: 0.25rem;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: var(--checkmark-color);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.buttons-container {
    margin: 1rem auto;
}

.custom-btn {
    width: 100%;
    max-width: 125px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.25rem;
    transition: all 0.35s ease-in-out;
    border: none;
    margin: 1.5rem auto 1rem;
}

.custom-btn:active {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: #ccc !important;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
}

.error-container {
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    color: var(--error);
    font-weight: 700;
    font-size: 0.75rem;
}

@media screen and (min-width: 768px) {
    .body-head,
    .consent-checkbox {
        font-size: 1.1rem;
    }

    .footer-container {
        padding: 4rem 3rem 1rem;
    }

    .checkmark {
        position: absolute;
        top: 10%;
        left: 0;
        height: 1.15rem;
        width: 1.1rem;
        background-color: #fff;
        border: 2px solid var(--checkmark-color);
        transition: all 0.15s ease-out;
    }
}

</style>
