<template>
    <div class="badge-wrapper">
        <div
            class="container-badge"
            :hovertext="alias"
        >
            <img
                :src="badgeUrl"
                :alt="`${alias}-badge`"
            >
            <span class="badge-text">
                {{ playerBadges }}
            </span>
        </div>
        <div class="badge-name">{{ alias }}</div>
    </div>
</template>

<script>
    export default {
        name: 'Badge',
        props: {
            badgeUrl: {
                type: String,
                default: ''
            },
            alias: {
                type: String,
                default: ''
            },
            playerBadges: {
                type: Number,
                default: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
.container-badge {
    max-width: 65px;
    width: max-content;
    height: max-content;
    position: relative;
}

.container-badge img {
    width: 100%;
    height: auto;
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
}

.badge-text {
    color: var(--white);
    position: absolute;
    top: 5%;
    left: 85%;
    width: 1.2rem;
    height: 1.2rem;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    font-weight: 700;
    display: grid;
    place-items: center;
    font-size: 0.85rem;
    background: rgb(255 0 0);
    text-shadow: 0 0 0.2rem rgb(0 0 0 / 30%);
}

.badge-name {
    max-width: 65px;
    margin-top: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.1;
}

@media screen and (min-width: 768px) {
    .badge-name {
        display: none;
    }
}
</style>
