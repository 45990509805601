module.exports = {
    publicPath: '/web/',
    transpileDependencies: ['vue-plyr', '@stomp/stompjs'],
    css: {
        loaderOptions: {
            scss: {
                prependData: `@import "~@/assets/styles/${process.env.VUE_APP_PALETTE ? process.env.VUE_APP_PALETTE : 'white-label'}/main.scss";`
            }
        }
    },
    chainWebpack: config => {
        config.resolve.alias
            .set('plyr', 'plyr/dist/plyr.polyfilled.min.js')
    }

}
