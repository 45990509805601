import { render, staticRenderFns } from "./TaskSVG.vue?vue&type=template&id=b18a03de&scoped=true&"
var script = {}
import style0 from "./TaskSVG.vue?vue&type=style&index=0&id=b18a03de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b18a03de",
  null
  
)

export default component.exports