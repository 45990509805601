<template>
    <b-modal
        id="task-end-modal"
        ref="task-end-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <div class="mt-3 text-alias">{{ alias }}</div>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar
                        :avatar="player.avatar || null"
                        :default-avatar="branding.defaultAvatarUrl"
                    />
                </b-col>
            </b-row>
        </div>
        <div class="footer-container">
            <div v-if="isPlayAgain">
                <div class="win-lose-text mt-5">
                    {{ $t('taskEndModal.playAgainText') }}
                </div>
            </div>
            <div v-else-if="pointsWon > 0">
                <div class="win-lose-text">{{ winText }}</div>
                <div class="points-text-number">
                    <span>{{ pointsWon }}</span>
                    <span class="points-text">{{ $t('taskEndModal.points') }}</span>
                </div>
                <div
                    v-if="!isLastTask && nextTaskPath !== '' && !shouldNotRedirect"
                    class="help-div vertical-align"
                >
                    <div
                        class="next-task"
                        @click="goToNextTask"
                    >
                        {{ $t('taskEndModal.goToNextTask') }}
                        <img v-if="theming.taskIcon" :src="theming.taskIcon" alt="" class="rocket-logo ml-2">
                        <rocket-svg v-else class="rocket-logo ml-2" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="win-lose-text">{{ loseText }}</div>
                <div
                    v-if="!isLastTask && nextTaskPath !== '' && !shouldNotRedirect"
                    class="help-div vertical-align"
                >
                    <div
                        class="next-task"
                        @click="goToNextTask"
                    >
                        {{ $t('taskEndModal.goToNextTask') }}
                        <img v-if="theming.taskIcon" :src="theming.taskIcon" alt="" class="rocket-logo ml-2">
                        <rocket-svg v-else class="rocket-logo ml-2" />
                    </div>
                </div>
            </div>
            <div v-if="isLastTask && !shouldNotRedirect">
                <badge-toast
                    v-if="badge"
                    :badge="badge"
                />
                <div
                    class="last-task-button"
                    @click="returnToHome"
                >
                    {{ $t('taskEndModal.goHome') }}
                    <img v-if="theming.taskIcon" :src="theming.taskIcon" alt="" class="rocket-logo ml-2">
                    <rocket-svg v-else class="rocket-logo ml-2" />
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import emitter from '@/utils/emitter'
    import Avatar from '@/components/Avatar'
    import RocketSvg from '@/components/svg/RocketSVG'
    import BadgeToast from '@/components/BadgeToast'
    export default {
        name: 'TaskEndModal',
        components: { Avatar, RocketSvg, BadgeToast },
        data() {
            return {
                task: {},
                pointsWon: 0,
                isLastTask: false,
                missionId: null,
                isPlayAgain: false,
                shouldNotRedirect: false,
                badge: null
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            },
            nextTaskPath() {
                return this.$store.getters.getNextTaskPath(this.missionId)
            },
            winText() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_WINTEXT'
                }
                return this.task.multilingualTask[0].config.winText
            },
            alias() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.task.multilingualTask[0].config.alias
            },
            loseText() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_LOSETEXT'
                }
                return this.task.multilingualTask[0].config.loseText
            },
            theming() {
                return this.$store.getters.getTheming
            }
        },
        created() {
            emitter.on('openTaskEndModal', (payload) => {
                if (payload.task) {
                    this.$bvModal.show('task-end-modal')
                    this.task = payload.task
                    this.pointsWon = payload.pointsWon
                    this.isLastTask = payload.isLastTask
                    this.isPlayAgain = payload.isPlayAgain
                    this.shouldNotRedirect = payload.shouldNotRedirect
                    this.missionId = payload.missionId
                    this.badge = payload.badge
                }
            })
            emitter.on('closeTaskEndModal', () => {
                this.$bvModal.hide('task-end-modal')
            })
        },
        methods: {
            hideModal() {
                this.$refs['task-end-modal'].hide()
            },
            returnToHome() {
                this.hideModal()
                this.$router.push('/home')
            },
            goToNextTask() {
                this.hideModal()
                this.$router.push(this.nextTaskPath)
            }
        }
    }
</script>

<style>
#task-end-modal.modal {
    backdrop-filter: blur(2px);
}
</style>

<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: cover, cover;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 3rem 0 2rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 130px;
    top: 100%;
    transform: translateY(-50%);
}

.text-alias {
    text-align: center;
    color: var(--white);
    font-weight: 700;
    font-size: 13px;
    width: 90%;
    margin: 0 auto;
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 160px;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.win-lose-text {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--black);
    text-align: center;
    max-width: 80%;
    margin: 1rem auto 1.5rem;
}

.points-text-number {
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--white);
    background: var(--secondary-color);
    max-width: 130px;
    margin: 1rem auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
}

.points-text {
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
}

.help-div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
}

.next-task,
.last-task-button {
    width: 90%;
    font-size: 1rem;
    text-align: center;
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
}

.last-task-button {
    display: block;
    margin: 1.5rem auto 0;
}

.rocket-logo {
    width: 1.75rem;
    height: 1.75rem;
    text-align: center;
    object-fit: contain;
}

@media screen and (min-width: 768px) {
    .rocket-logo {
        width: 3rem;
        height: 3rem;
    }

    .next-task,
    .last-task-button {
        font-size: 1.25rem;
    }

    .last-task-button {
        display: block;
        margin: 2rem auto 0;
    }
}
</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
