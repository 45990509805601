export default {
    methods: {
        async universalTryCatch(promise) {
            try {
                const data = await promise
                return [data, null]
            } catch (error) {
                return [null, error]
            }
        },
        async recaptcha(action) {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha(action)
            return token
        }
    }
}
