export default {
    data() {
        return {
            config: {
                host: process.env.VUE_APP_API_HOST_DOMAIN,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN + process.env.VUE_APP_API_BASE_PATH,
                wsUrl: process.env.VUE_APP_API_AVIATION_HOST_DOMAIN,
                environment: process.env.VUE_APP_ENV,
                gameServerHost: process.env.VUE_APP_GAME_SERVER_HOST
            }
        }
    }
}
