export default {
    state: {
        freeTaskInfo: localStorage.getItem('freeTaskInfo') ? JSON.parse(localStorage.getItem('freeTaskInfo')) : null
    },
    mutations: {
        setFreeTaskInfo(state, freeTaskInfo) {
            state.freeTaskInfo = freeTaskInfo
            localStorage.setItem('freeTaskInfo', JSON.stringify(freeTaskInfo))
        },
        clearFreeTaskInfo(state) {
            state.freeTaskInfo = null
            localStorage.removeItem('freeTaskInfo')
        }
    }
}
