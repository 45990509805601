<template>
    <b-modal
        id="challenge-end-modal"
        ref="challenge-end-modal"
        class="modal-content"
        hide-header
        hide-footer
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt="branding"
                    />
                </b-col>
            </b-row>
            <div class="mt-3 text-alias">{{ alias }}</div>
        </div>
        <div class="main-container">
            <div class="middle-section">
                <b-row class="avatars-container">
                    <b-col class="avatar-wrapper">
                        <div class="avatar-container">

                            <avatar
                                :class="{'loser-overlay': isLoser(challenge.creatorChallengerId)}"
                                :avatar="challenge.creatorAvatar || null"
                                :default-avatar="branding.defaultAvatarUrl"
                            />
                        </div>
                        <span class="avatar-name" :class="{'loser-overlay': isLoser(challenge.creatorChallengerId)}">{{ challenge.creatorChallengerId }}</span>
                    </b-col>
                    <div>
                        <img :src="theming.arenaVsIcon" alt="vs" class="versus-img">
                    </div>
                    <b-col class="avatar-wrapper">
                        <div class="avatar-container">
                            <avatar
                                :class="{'loser-overlay': isLoser(challenge.joinerChallengerId)}"
                                :avatar="challenge.joinerAvatar || null"
                                :default-avatar="branding.defaultAvatarUrl"
                            />
                        </div>
                        <span
                            class="avatar-name"
                            :class="{'loser-overlay': isLoser(challenge.joinerChallengerId)}"
                        >{{ challenge.joinerChallengerId }}</span>
                    </b-col>
                </b-row>
                <div class="score-container">
                    <span class="score-time-value" :class="{'loser-overlay': isLoser(challenge.creatorChallengerId)}">{{ creatorPoints }}</span>
                    <span>{{ $t('challengeEndModal.score') }}</span>
                    <span class="score-time-value" :class="{'loser-overlay': isLoser(challenge.joinerChallengerId)}">{{ joinerPoints }}</span>
                </div>
                <div class="time-container">
                    <span class="score-time-value" :class="{'loser-overlay': isLoser(challenge.creatorChallengerId)}">{{ creatorDuration }}</span>
                    <span>{{ $t('challengeEndModal.time') }}</span>
                    <span class="score-time-value" :class="{'loser-overlay': isLoser(challenge.joinerChallengerId)}">{{ joinerDuration }}</span>
                </div>
            </div>
            <div class="footer-container">
                <div v-if="!challenge.challengeJoinerEndDate" class="text-points-wrapper">
                    <span class="win-lose-text">{{ waitingText }}</span>
                </div>
                <div v-else-if="pointsWon > 0" class="text-points-wrapper">
                    <div class="win-lose-text">{{ winText }}</div>
                    <div class="points-text-number">
                        <span>{{ pointsWon }}</span>
                        <span class="points-text">{{ $t('taskEndModal.points') }}</span>
                    </div>
                </div>
                <div v-else class="text-points-wrapper">
                    <span class="win-lose-text">{{ loseText }}</span>
                </div>
                <div>
                    <div
                        class="challenge-end-button"
                        @click="returnToChallenges"
                    >
                        {{ $t('challengeEndModal.goToChallenges') }}
                        <img v-if="theming.taskIcon" :src="theming.taskIcon" alt="taskIcon" class="rocket-logo ml-2">
                        <rocket-svg v-else class="rocket-logo ml-2" />
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import emitter from '@/utils/emitter'
    import Avatar from '@/components/Avatar'
    import RocketSvg from '@/components/svg/RocketSVG'
    import dateConversion from '@/utils/date-conversion'
    export default {
        name: 'ChallengeEndModal',
        components: { Avatar, RocketSvg },
        data() {
            return {
                challenge: {},
                pointsWon: 0
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            },
            winText() {
                if (!this.challenge?.locale?.winText) {
                    return 'NO TRANSLATION WIN TEXT'
                }
                return this.challenge.locale.winText
            },
            alias() {
                if (!this.challenge?.locale?.alias) {
                    return 'NO TRANSLATION ALIAS'
                }
                return this.challenge.locale.alias
            },
            loseText() {
                if (!this.challenge?.locale?.loseText) {
                    return 'NO TRANSLATION LOSE TEXT'
                }
                return this.challenge.locale.loseText
            },
            waitingText() {
                if (!this.challenge?.locale?.waitingText) {
                    return 'NO TRANSLATION WAIT TEXT'
                }
                return this.challenge.locale.waitingText
            },
            theming() {
                return this.$store.getters.getTheming
            },
            creatorPoints() {
                return this.challenge.challengeCreatorPoints
            },
            joinerPoints() {
                return this.challenge.challengeJoinerEndDate ? this.challenge.challengeJoinerPoints : '-'
            },
            creatorDuration() {
                return this.getPaddingTime(this.challenge.challengeCreatorGameDuration)
            },

            joinerDuration() {
                return this.challenge.challengeJoinerEndDate ? this.getPaddingTime(this.challenge.challengeJoinerGameDuration) : '-'
            }
        },
        created() {
            emitter.on('openChallengeEndModal', (payload) => {
                if (payload) {
                    this.challenge = payload
                    this.pointsWon = payload.userPoints
                    this.$bvModal.show('challenge-end-modal')
                }
            })
            emitter.on('closeChallengeEndModal', () => {
                this.$bvModal.hide('challenge-end-modal')
            })
        },
        methods: {
            returnToChallenges() {
                this.hideModal()
                this.$router.push({ name: 'Challenges' })
            },
            hideModal() {
                this.$refs['challenge-end-modal'].hide()
            },
            getPaddingTime(time) {
                return dateConversion.getPaddedDigitsString(time, false)
            },
            isLoser(challengerId) {
                return this.challenge.status === 'COMPLETED' && this.challenge.winnerChallengerId && this.challenge.winnerChallengerId !== challengerId
            },
            playersNotTied() {
                const playersTiedOnPoints = this.challenge.challengeCreatorPoints === this.challenge.challengeJoinerPoints
                const playersTiedOnTime = this.challenge.challengeCreatorGameDuration === this.challenge.challengeJoinerGameDuration
                return playersTiedOnPoints && playersTiedOnTime
            }
        }
    }
</script>

<style>
#challenge-end-modal.modal {
    backdrop-filter: blur(2px);
}
</style>

<style lang="scss" scoped>
.modal-content {
    background: transparent !important;
    border: none !important;
}

.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: cover, cover;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 160px;
    height: auto;
}

.text-alias {
    text-align: center;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 700;
    width: 90%;
    margin: 0 auto;
}

.main-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 0 0 1rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.middle-section {
    width: 75%;
    margin: 0 auto;
}

.loser-overlay {
    opacity: 0.6;
}

.avatars-container {
    display: flex;
    justify-content: center;
    transform: translateY(-35%);
}

.avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-container {
    max-width: 75px;
    text-align: center;
    position: relative;
}

.avatar-loser-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    opacity: 0.6;
    z-index: 1;
    border-radius: 50%;
}

.avatar-name {
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: 0.5rem;
}

.versus-img {
    max-width: 60px;
    transform: translateY(80%);
}

.score-container,
.time-container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    align-items: end;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
}

.score-container span,
.time-container span {
    justify-self: center;
    align-self: center;
}

.score-time-value {
    font-size: 1.1rem;
    font-weight: 800;
}

.footer-container {
    margin-top: 2rem;
}

.text-points-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.win-lose-text {
    font-weight: 700;
    font-size: 1.2rem;
    color: var(--black);
    text-align: center;
    max-width: 80%;
    margin: 1rem auto 0;
}

.points-text-number {
    font-weight: 700;
    font-size: 1.35rem;
    color: var(--white);
    background: var(--secondary-color);
    max-width: 120px;
    width: 100%;
    margin: 0.5rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.points-text {
    font-weight: 700;
    font-size: 0.75rem;
    text-align: center;
}

.challenge-end-button {
    display: block;
    margin: 0 auto;
    width: 90%;
    font-size: 1rem;
    text-align: center;
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
}

.rocket-logo {
    width: 1.75rem;
    height: 1.75rem;
    text-align: center;
    object-fit: contain;
}

@media screen and (min-width: 768px) {
    .rocket-logo {
        width: 3rem;
        height: 3rem;
    }

    .challenge-end-button {
        font-size: 1.25rem;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (min-width: 992px) {
    .text-alias {
        font-size: 1.4rem;
    }

    .main-container {
        background: var(--white);
        width: 100%;
        min-height: 300px;
        padding: 0 0 1rem;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .avatar-container {
        max-width: 90px;
    }

    .avatar-name {
        margin-top: 1.2rem;
        font-size: 1.1rem;
    }

    .versus-img {
        max-width: 80px;
    }

    .score-container,
    .time-container {
        font-size: 1rem;
    }

    .time-container {
        margin-top: 1rem;
    }

    .score-time-value {
        font-size: 1.3rem;
    }

    .footer-container {
        margin-top: 3rem;
    }

    .win-lose-text {
        font-size: 1.5rem;
        margin: 1rem auto 0;
    }

    .points-text-number {
        font-size: 1.65rem;
        max-width: 150px;
        margin: 1rem auto;
        padding: 0.7rem 1rem;
    }

    .points-text {
        font-size: 0.875rem;
    }

    .challenge-end-button {
        font-size: 1.2rem;
        color: var(--black);
    }
}
</style>
