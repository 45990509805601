import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

const messages = { en: {}, el: {} }

async function initTranslations(store) {
    await store.dispatch('initConfig', true)
    const config = store.getters.getConfig
    if (config.locale) {
        let availableLanguages = []
        Object.keys(config.locale).forEach(key => {
            availableLanguages.push(key.toUpperCase())
            messages[key] = JSON.parse(config.locale[key])
        })
        store.commit('setAvailableLanguages', availableLanguages)
    }
    return new VueI18n({
        locale: store.getters.getLanguage.toLowerCase() || 'en',
        messages
    })
}

export default initTranslations
