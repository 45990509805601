import missionService from '@/services/mission-service'

export default {
    state: {
        missions: [],
        missionsLoading: false
    },
    mutations: {
        setMissions(state, missions) {
            state.missions = missions
        },
        setMissionsLoading(state, loading) {
            state.missionsLoading = loading
        },
        setMission(state, mission) {
            const index = state.missions.findIndex(m => m.id === mission.id)
            if (index) {
                state.missions[index] = mission
            }
        }
    },
    actions: {
        refreshMissions({ commit }) {
            commit('setMissionsLoading', true)
            missionService.getMissions().then((res) => {
                let missions = res.data
                missions.sort((a, b) => {
                    return (a.position || Number.MAX_VALUE) - (b.position || Number.MAX_VALUE)
                })
                missions.sort((a, b) => {
                    return a.locked - b.locked
                })
                missions.sort((a, b) => {
                    return a.completed - b.completed
                })
                commit('setMissions', missions)
                commit('setMissionsLoading', false)
            })
        },
        startTask({ getters, dispatch }, { missionId, taskId }) {
            if (!getters.isPreviewMode && !getters.isTaskCompleted(missionId, taskId)) {
                missionService.startTask(missionId, taskId)
                    .then(() => {
                        console.log('task with ' + taskId + ' was started')
                    }, () => {
                        console.log('task with ' + taskId + ' is already finished or is not next')
                        dispatch('startTask')
                        return true
                    })
            }
        }
    }
}
