<template>
    <b-modal
        id="submit-code-modal"
        ref="submit-code-modal"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <b-container class="modal-body">
            <b-button class="close-button" @click="hideModal()"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col />
            </b-row>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="w-25 mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar
                        :avatar="player.avatar || null"
                        :default-avatar="branding.defaultAvatarUrl"
                    />
                </b-col>
            </b-row>
            <div class="footer-container">
                <div>
                    <div class="win-text">{{ successMessage }}</div>
                    <div class="points-text-number">
                        <span>
                            {{ pointsWon }}
                        </span>
                        <span class="points-text">{{ $t('submitCodeModal.points') }}</span>
                    </div>
                </div>
            </div>
        </b-container>
    </b-modal>
</template>

<script>

    import emitter from '@/utils/emitter'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'SubmitCodeModal',
        components: { Avatar },

        data() {
            return {
                pointsWon: 0,
                successMessage: ''
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            }
        },
        created() {
            emitter.on('openSubmitCodeModal', (payload) => {
                if (payload.pointsWon) {
                    this.$bvModal.show('submit-code-modal')
                    this.pointsWon = payload.pointsWon
                    this.successMessage = payload.successMessage
                }
            })
        },
        methods: {
            hideModal() {
                this.$refs['submit-code-modal'].hide()
                this.$router.push({ path: '/home' })
            }
        }
    }
</script>

<style>
#submit-code-modal.modal {
    backdrop-filter: blur(2px);
}
</style>

<style lang="scss" scoped>
.modal-body {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: cover, cover;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    min-height: 400px !important;
    max-width: 500px !important;
    top: -32px;
    border-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    position: absolute;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: 1;
}

.logo-center {
    text-align: center;
}

.avatar-container {
    text-align: center;
    position: absolute;
    max-width: 130px;
    top: 28%;
    z-index: 2;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.win-text {
    font-weight: 700;
    font-size: 1.65rem;
    color: var(--black);
    text-align: center;
    margin-top: 80px;
}

.points-text-number {
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--white);
    background: var(--secondary-color);
    max-width: 130px;
    margin: 1rem auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
}

.points-text {
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
