import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import initTranslations from '@/locale/initTranslations'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'focus-within-polyfill'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowCircleLeft,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faPlusCircle,
    faPaperPlane,
    faStar,
    faLock,
    faBolt,
    faHome,
    faCloudUploadAlt,
    faSearch,
    faCheck,
    faHourglassHalf,
    faInfoCircle,
    faThumbsUp,
    faThumbsDown,
    faTimes
} from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'es5-shim'
import 'es6-shim'
import 'es6-symbol/implement'
import momentTimezone from 'moment-timezone'
import router from './router'
import store from '@/store'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VuePlyr from 'vue-plyr'
import Vue2TouchEvents from 'vue2-touch-events'
import { SFacebook } from 'vue-socials'
import requestMixin from '@/mixins/request-mixin'
import config from '@/mixins/config-mixin'
import toastConfig from '@/mixins/toaster-mixin'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import VueMeta from 'vue-meta'
import VueCompositionAPI from '@vue/composition-api'

library.add(
    faChevronLeft,
    faTimesCircle,
    faCheckCircle,
    faPlusCircle,
    faPaperPlane,
    faStar,
    faLock,
    faBolt,
    faHome,
    faCloudUploadAlt,
    faSearch,
    faCheck,
    faHourglassHalf,
    faArrowCircleLeft,
    faChevronRight,
    faInfoCircle,
    faThumbsUp,
    faThumbsDown,
    faChevronUp,
    faChevronDown,
    faTimes
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('SFacebook', SFacebook)
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)
Vue.use(Vuex)
Vue.use(VuePlyr)
Vue.use(VueMeta)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_REPCAPTCHA_SITE })
Vue.use(momentTimezone)
Vue.use(Vue2TouchEvents)
Vue.mixin(requestMixin)
Vue.mixin(toastConfig)
Vue.mixin(config)

Vue.use(VueDOMPurifyHTML, {
    default: {
        ALLOWED_TAGS: [
            'iframe',
            'img',
            'br',
            'span',
            'p',
            'strong',
            'em',
            'a',
            'ol',
            'li',
            'ul'
        ],
        ADD_ATTR: ['src', 'allowfullscreen', 'frameborder', 'allow', 'allow-forms', 'allow-same-origin', 'class', 'width', 'height']
    }
});

// IIFE function to initialize the app (avoid warning about not handling the promise)
(async () => {
    await initApp()
})()

async function initApp() {
    const i18n = await initTranslations(store)

    Vue.config.productionTip = false

    Vue.filter('striphtml', (value) => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent || div.innerText || ''
    })

    const app = new Vue({
        store,
        router,
        i18n,
        render: h => h(App)
    })
    store.$app = app

    app.$mount('#app')
}
