import restApi from '@/utils/rest-api'

export default {
    getCurrentPlayer: () => {
        return restApi.get('players/current')
    },
    updateCurrentPlayer: (player) => {
        return restApi.put('players/current', player)
    },
    loginWithCredentials: (data) => {
        return restApi.post('players/login', data)
    },
    loginWithAccessCode: ({ accessCode, reCaptchaResponse }) => {
        return restApi.post('players/code-login', {
            accessCode,
            reCaptchaResponse
        })
    },
    emailRegister(emailOrMobile, reCaptchaResponse) {
        return restApi.post('players/email-register', {
            emailOrMobile,
            reCaptchaResponse
        })
    },
    mobileRegister(emailOrMobile, reCaptchaResponse) {
        return restApi.post('players/mobile-register', {
            emailOrMobile,
            reCaptchaResponse
        })
    },
    passwordReset: (emailOrMobile) => {
        return restApi.post('players/password-reset', { emailOrMobile })
    },
    newPassword: (password, resetToken) => {
        return restApi.put('players/password', { password, resetToken })
    },
    updateCredentials: (data) => {
        return restApi.post('players/update-credentials', data)
    },
    changeMobile: (mobileNumber) => {
        return restApi.post('players/change-mobile', { emailOrMobile: mobileNumber })
    },
    verifyMobile: (otpCode) => {
        return restApi.post('players/verify-mobile', { code: otpCode })
    },
    changeEmail: (email) => {
        return restApi.post('players/change-email', { emailOrMobile: email })
    },
    tokenLogin: (confirmationCode) => {
        return restApi.post('players/verify-email', { code: confirmationCode })
    },
    toggleMetaDataOption: (toggleOption) => {
        return restApi.post('players/toggle-metadata-option', { option: toggleOption })
    },
    unsubscribe: (playerId) => {
        return restApi.post('players/notifications-unsubscribe', { playerId })
    },
    challengeUnsubscribe: (playerId) => {
        return restApi.post('players/challenge-notifications-unsubscribe', { playerId })
    },
    updateChallengerId: (challengerId) => {
        return restApi.put('players/challenger-id', { content: challengerId })
    }
}
