export default {
    state: {
        todayFlights: [],
        upcomingFlights: []
    },
    mutations: {
        setTodayFlights(state, flights) {
            flights.sort((a, b) => a.flightDepEstimated - b.flightDepEstimated)
            const statusOrder = ['scheduled', 'cancelled', 'active', 'landed']
            flights.sort((a, b) => statusOrder.indexOf(a.flightStatus) - statusOrder.indexOf(b.flightStatus))
            state.todayFlights = flights
        },
        setUpcomingFlights(state, flights) {
            state.upcomingFlights = flights
        }
    }
}
