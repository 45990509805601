import restApi from '@/utils/rest-api'

export default {
    getFreeTasks() {
        return restApi.get('tasks/free')
    },
    getOtp(taskId, reCaptchaResponse) {
        return restApi.post(`tasks/${taskId}/otp-request`, { content: reCaptchaResponse })
    },
    taskStatus(taskId, accessCode) {
        return restApi.post(`tasks/${taskId}/status`, {
            accessCode,
            reCaptchaResponse: null
        })
    }
}
