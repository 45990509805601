<template>
    <b-modal
        id="rewards-modal"
        ref="rewards-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar
                        :avatar="player.avatar || null"
                        :default-avatar="branding.defaultAvatarUrl"
                    />
                </b-col>
            </b-row>
        </div>

        <div class="footer-container">
            <div>
                <div v-if="pointsToRedeem && !errorMessage" class="points-text">
                    {{ $t('redeemedPointsModal.successMessage', {pointsToRedeem}) }}
                </div>
                <div v-else class="points-text">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import Avatar from '@/components/Avatar'
    import emitter from '@/utils/emitter'
    export default {
        components: { Avatar },

        data() {
            return {
                pointsToRedeem: 0
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            errorMessage() {
                return this.$store.getters.getErrorMessage
            }
        },
        created() {
            emitter.on('openRewardsModal', (payload) => {
                if (payload && payload.pointsRedeemed) {
                    this.pointsToRedeem = payload.pointsRedeemed
                }
                this.$bvModal.show('rewards-modal')
            })
        },
        methods: {
            hideModal() {
                this.$refs['rewards-modal'].hide()
                this.$store.commit('setErrorMessage', null)
            }
        }
    }
</script>
<style>
#rewards-modal.modal {
    backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: cover, cover;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 3rem 0 2rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 130px;
    top: 100%;
    transform: translateY(-50%);
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 160px;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.points-text {
    font-weight: 700;
    font-size: 1.1rem;
    color: var(--black);
    text-align: center;
    margin: 0 auto;
    padding: 50px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
