import restApi from '@/utils/rest-api'

export default {
    getPendingChallengeSessions: () => {
        return restApi.get(`challenge-sessions/pending`)
    },
    getHistoryChallengeSessions: () => {
        return restApi.get('challenge-sessions/history')
    },
    getRemainingChallengeSessions: () => {
        return restApi.get('challenge-sessions/remaining-today')
    },
    deleteChallengeSession: (id) => {
        return restApi.delete(`challenge-sessions/${id}`)
    },
    checkValidity(requestData) {
        return restApi.post(`challenge-sessions/check-valid`, requestData)
    }
}
