<template>
    <div>
        <img
            v-if="!hasAvatar"
            class="avatar"
            :src="defaultAvatar || branding.defaultAvatarUrl"
            :style="cssVars"
            alt="default avatar"
        >
        <img
            v-else
            :src="`${avatar.imageUrl}`"
            class="avatar"
            :style="cssVars"
            alt="user avatar"
        >
    </div>
</template>
<script>
    export default {
        props: {
            avatar: {
                type: Object,
                default: null
            },
            defaultAvatar: {
                type: String,
                default: null
            },
            avatarWidth: {
                type: String,
                default: '100%'
            },
            borderWidth: {
                type: String,
                default: '2px'
            },
            borderColor: {
                type: String,
                default: 'white'
            },
            maxAvatarWidth: {
                type: String,
                default: null
            }
        },
        computed: {
            cssVars() {
                return {
                    '--width': this.avatarWidth,
                    '--border-width': this.borderWidth,
                    '--border-color': this.borderColor,
                    '--max-width': this.maxAvatarWidth
                }
            },
            branding() {
                return this.$store.getters.getBranding
            },
            hasAvatar() {
                return this.avatar?.imageUrl
            }
        }
    }
</script>
<style lang="scss" scoped>
.avatar {
    border-radius: 50%;
    border: 2px solid var(--border-color);
    width: var(--width);
    max-width: var(--max-width);
}

</style>
