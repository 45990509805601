<template>
    <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="20.5"
            cy="20.5"
            r="20.5"
            class="svg-background"
            :style="{ fill: svgBackground }"
        />
        <path
            class="svg"
            :style="{
                fill: svgColor,
                stroke: svgColor
            }"
            d="M30.4325 13.6007L27.3995 10.5677C27.0334 10.2016 26.5466 10 26.0289 10C25.5111 10 25.0244 10.2016 24.6583 10.5677L11.962 23.2638C11.8415 23.3843 11.7638 23.541 11.7409 23.7099L11.0071 29.1161C10.9744 29.3573 11.0561 29.5998 11.2282 29.7718C11.3753 29.9189 11.5738 30 11.779 30C11.8138 30 11.8489 29.9977 11.8839 29.9929L17.2901 29.2591C17.459 29.2362 17.6157 29.1586 17.7362 29.038L30.4325 16.342C30.7986 15.9759 31.0002 15.4891 31.0002 14.9714C31.0002 14.4536 30.7986 13.9668 30.4325 13.6007ZM16.8202 27.7505L12.6887 28.3112L13.2495 24.1798L22.2465 15.183L25.8171 18.7537L16.8202 27.7505ZM29.3307 15.2402L26.9189 17.6519L23.3482 14.0812L25.76 11.6695C25.8569 11.5726 25.97 11.5581 26.0289 11.5581C26.0877 11.5581 26.2008 11.5726 26.2977 11.6695L29.3307 14.7025C29.4276 14.7994 29.4421 14.9125 29.4421 14.9713C29.4421 15.0301 29.4276 15.1433 29.3307 15.2402Z"
        />
    </svg>
</template>
<script>
    export default {
        name: 'EditIconSVG',
        props: {
            svgBackground: {
                type: String,
                default: 'var(--sidebar-highlight-color)'
            },
            svgColor: {
                type: String,
                default: 'var(--sidebar-highlight-text-color)'
            }
        }
    }
</script>

<style lang="scss" scoped>
.svg-background {
    fill: var(--sidebar-highlight-color);
}

.svg {
    fill: var(--sidebar-highlight-text-color);
    stroke: var(--sidebar-highlight-text-color);
}
</style>
