import restApi from '@/utils/rest-api'

export default {
    getChallengesAvailable: () => {
        return restApi.get(`challenges/available`)
    },
    getChallenge: (id) => {
        return restApi.get(`challenges/${id}`)
    },
    createChallenge: (challenge) => {
        return restApi.post(`challenges`, challenge)
    },
    updateChallenge: (challenge) => {
        return restApi.put(`challenges/${challenge.id}`, challenge)
    },
    deleteChallenge: (id) => {
        return restApi.delete(`challenges/${id}`)
    },
    getChallengeSessions: () => {
        return restApi.get(`challenge-sessions`)
    },
    createChallengeSession: (data) => {
        return restApi.post(`challenge-sessions`, data)
    },
    challengeSessionStart: (data) => {
        return restApi.post(`challenge-sessions/start`, data)
    },
    challengeSessionFinish: (data) => {
        return restApi.post(`challenge-sessions/finish`, data)
    }
}
