<template>
    <b-modal
        id="email-verification-modal"
        ref="email-verification-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar
                        :avatar="player.avatar || null"
                        :default-avatar="branding.defaultAvatarUrl"
                    />
                </b-col>
            </b-row>
        </div>
        <div class="footer-container">
            <div class="title">{{ $t('emailVerificationModal.verifyEmail') }}</div>
            <b-button
                class="ok-button"
                @click="hideModal"
            >
                {{ $t('emailVerificationModal.ok') }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'EmailVerificationModal',
        components: { Avatar },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            }
        },
        created() {
            emitter.on('openEmailVerificationModal', (email) => {
                this.$bvModal.show('email-verification-modal')
            })
        },
        methods: {
            hideModal() {
                this.$refs['email-verification-modal'].hide()
            }
        }
    }
</script>

<style>
#email-verification-modal.modal {
    backdrop-filter: blur(2px);
}
</style>

<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.ok-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
}

.header-container {
    background:
        linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%),
        linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-position: center;
    background-size: cover, cover;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 311px;
    padding: 3rem 2rem 2rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--black);
}

.avatar-container {
    position: absolute;
    width: 130px;
    top: 100%;
    transform: translateY(-50%);
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 160px;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
