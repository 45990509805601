<template>
    <div class="toast-container">
        <div class="success-message">
            {{ $t('badgeToast.congrats', {alias: badge.alias}) }}
        </div>
        <img class="badge-img" :src="badge.imageUrl" alt="badge image">
    </div>
</template>

<script>
    export default {
        props: {
            badge: {
                type: Object,
                required: false,
                default: () => ({
                    imageUrl: '',
                    alias: ''
                })
            }
        }
    }
</script>

<style scoped>
.toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 2px solid var(--secondary-color);
    box-shadow: 0 2px 5px var(--secondary-color);
    border-radius: 1.5rem;
    max-width: 70%;
    margin: 2rem auto 0;
}

.success-message {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 700;
    color: #1b8fbd;
}

.badge-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .toast-container {
        padding: 1rem 2rem;
    }

    .success-message {
        font-size: 1.25rem;
    }
}
</style>
