import challengeService from '@/services/challenge-service'

export default {
    state: {
        availableChallenges: []
    },
    mutations: {
        setAvailableChallenges: (state, challenges) => {
            state.availableChallenges = challenges
        }
    },
    actions: {
        async fetchAvailableChallenges({ commit }) {
            const config = await challengeService.getChallengesAvailable()
            commit('setAvailableChallenges', config.data)
            return Promise.resolve()
        }
    }
}
